import type { Modifier, ModifierPhases } from '@popperjs/core'
import { clone, isEmpty, isNull, isObject, isUndefined } from 'lodash-es'
import moment from 'moment'
import type { DB } from './db'
import type CatalogPriceGroup from '@/models/catalogPriceGroup'
import type MyArticle from '@/models/myArticle'
import type WbFrame from '@/modules/whiteboard/services/frame'
import { AttributeType } from '@/models/catalogAttribute'
import type DuneAsset from '@/models/duneAsset'
import Article from '@/models/article'
import type RequestModel from '@/models/request'
import type CatalogDetails from '@/models/catalogDetails'
import type { Price } from '@/models/articlePrice'
import { requestConstants } from '@/models/constants'
import i18n from '@/i18n'
import type { ITxDataTableColumn } from '@/shared/components/txDataTable/TxDataTable.types'

/**
 * @description: check for null and undefined value
 * @param { any } val any value to check for null or undefined
 * @return return true if given value is defined and not null
 */
function isDefined<T>(val: T | undefined | null): val is T {
  return val !== undefined && val !== null
}

/**
 * @description Return the passed val if it is defined, otherwise will return def
 * @param val a value to test if null or undefined
 * @param def the default value to return incase val was not defined
 * @returns val or def if val is not defined
 */
function ifUndefined<T>(val: T | undefined | null, def: T): T {
  return isDefined(val) ? val : def
}

/**
 * @description will format date base on format provided or default format ('D MMM YYYY')
 * @param {string} value to be format
 */
function formatDate(value) {
  // format local date
  if (!value) {
    return ''
  }
  else if (validateDate(value)) {
    const d = new Date(value)
    // fix time zone issue
    const dateWithOutTimeZoneDifference = d.setMinutes(d.getMinutes() + d.getTimezoneOffset())
    const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(dateWithOutTimeZoneDifference)
    const mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(dateWithOutTimeZoneDifference)
    const da = new Intl.DateTimeFormat('en', { day: 'numeric' }).format(dateWithOutTimeZoneDifference)
    return `${da} ${mo} ${ye}`
  }
  else {
    return 'Invalid Date'
  }
}

/**
 * @description will format date time base on format provided or default format ('D MMM YYYY h:mm:ss a')
 * @param {string} value to be format
 */
function formatDateTime(value) {
  // format local date
  if (!value) {
    return ''
  }
  else if (validateDate(value)) {
    const d = new Date(value)
    const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d)
    const mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(d)
    const da = new Intl.DateTimeFormat('en', { day: 'numeric' }).format(d)
    const time = new Intl.DateTimeFormat('en', { hour: 'numeric', minute: 'numeric', second: 'numeric' }).format(d)
    return `${da} ${mo} ${ye} ${time}`
  }
  else {
    return 'Invalid Date'
  }
}

/**
 * @description will format date base on format provided or default format ('D MMM YYYY') and show UTC time (without converting to local time)
 * @param {*} value to be format
 */
function formatDateUTC(value) {
  // convert UTC date to local and format (if date created in UTC should use this filter to display date in local)
  if (!value) {
    return ''
  }
  else if (validateDate(value)) {
    const d = new Date(value) // TODO: Need to test it
    const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d)
    const mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(d)
    const da = new Intl.DateTimeFormat('en', { day: 'numeric' }).format(d)
    return `${da} ${mo} ${ye}`
  }
  else {
    return 'Invalid Date'
  }
}

function formatPrice(priceGroup: CatalogPriceGroup | undefined, price: number, showPriceThousandsSeparated = false) {
  let res = ''
  if (priceGroup && priceGroup.CurrencyCode) {
    res = `${priceGroup.CurrencyCode} `
  }
  if (isDefined(price)) {
    res += !showPriceThousandsSeparated ? price : thousandsSeparator(price)
  }
  else {
    res += '-'
  }
  return res
}

/**
 * @description a method to validate the date
 */
function validateDate(value) {
  const timestamp = Date.parse(value)
  return !Number.isNaN(timestamp)
}

function isValidDateWithFormat(date, formats) {
  if (isDefined(formats)) {
    formats = [
      moment.ISO_8601,
    ]
  }
  // The true argument is there so the Moment won't try to parse the input if it doesn't exactly conform to one of the formats provided
  if (moment(date, formats, true).isValid()) {
    return true
  }
  else {
    return false
  }
}

function isValidStringValue(value) {
  return isDefined(value) && value.toString().trim() !== ''
}

function getAdminAttributeValue(field: ITxDataTableColumn, value: any, lookupData: Record<string, any>, activeCatalog: CatalogDetails) {
  if (isNull(value) || isUndefined(value)) {
    if (field.property.includes('_Segmentations')) {
      return 'No'
    }
    return ''
  }
  if (field.property.includes('_Prices')) {
    if (value.Price) {
      return value.Price.toLocaleString(undefined, { minimumFractionDigits: activeCatalog!.Config.NumberOfDecimalsForPrices })
    }
    else {
      return 0
    }
  }
  else if (field.property.includes('StatesTimestamp')) {
    return formatDate(value)
  }
  else if (field.property.includes('_Segmentations')) {
    if (activeCatalog!.SegmentationPlanningEnable && value.PlanningValue) {
      return value.PlanningValue
    }
    else {
      return 'Yes'
    }
  }
  else {
    switch (field.type) {
      case AttributeType.ArticleNumber:
      case AttributeType.Calc:
      case AttributeType.LinkedCatalogArticleNumber:
      case AttributeType.Nvarchar:
      case AttributeType.SellerModel:
      case AttributeType.SizeScale:
      case AttributeType.MasterSizeScale:
        return value
      case AttributeType.Int:
        return value.toLocaleString()
      case AttributeType.Decimal:
        return value.toLocaleString(undefined, { minimumFractionDigits: activeCatalog!.Config.NumberOfDecimalsForPrices })
      case AttributeType.BoolInt:
        return value === 1 ? 'True' : 'False'
      case AttributeType.IntBool:
        return value === '1' ? 'True' : 'False'
      case AttributeType.Date:
      case AttributeType.DateOption:
        return formatDate(value)
      case AttributeType.DateTime:
        return formatDateTime(value)
      case AttributeType.TimeAgo:
        return new Date(value)
      case AttributeType.MultiValue:
        return value.join('|')
      case AttributeType.Status:
        if (activeCatalog!.DataSourceTypeId === 3) {
          switch (value) {
            case 1:
              return i18n.global.t('general.active')
              break
            case 0:
              return i18n.global.t('general.inactive')
              break
            case 2:
              return i18n.global.t('general.notAssorted')
              break
            case 3:
              return i18n.global.t('general.globallyDropped')
              break
            default:
              return value && value === 1 ? i18n.global.t('general.active') : i18n.global.t('general.inactive')
              break
          }
        }
        return value ? i18n.global.t('general.active') : i18n.global.t('general.inactive')
      case AttributeType.Lookup:
      case AttributeType.LookupMany: // TODO when we need to create chip for lookup many currently in article module not in use
      case AttributeType.ModelNumber:
        if (lookupData && lookupData[value]) {
          const lookupValue = lookupData[value]
          if (field.lookupDisplayField && isObject(lookupValue)) {
            return lookupValue[field.lookupDisplayField]
          }
          return lookupValue
        }
        else {
          return value.toString()
        }
      case AttributeType.ColorPalette: {
        // TODO: We need to work on this when working on AttributeType.ColorPalette
        const parsedValue = tryParse(value.toString())
        if (parsedValue) {
          const values = Object.values(parsedValue) as string[]
          return values.length > 0 ? values[0] : parsedValue
        }
        else {
          return value.toString()
        }
      }
      case AttributeType.Bool:
        return value ? 'True' : 'False'
      case AttributeType.ArticleStatus:
      // if child catalog show"Not Assorted" and "Globally Dropped" statuses
        if (field.property === 'Status') {
          if (activeCatalog!.DataSourceTypeId === 3) {
            switch (value) {
              case 1 :
                return i18n.global.t('general.active')
              case 0 :
                return i18n.global.t('general.inactive')
              case 2 :
                return i18n.global.t('general.notAssorted')
              case 3 :
                return i18n.global.t('general.globallyDropped')
              default:
                return ''
            }
          }
          else {
            return value ? i18n.global.t('general.active') : i18n.global.t('general.inactive')
          }
        }
        else {
          return value ? i18n.global.t('general.yes') : i18n.global.t('general.no')
        }
      default:
        return ''
    }
  }
}

async function getAttributeTypeSpecificValue(attribute: IMyAttribute, article: MyArticle | Article, db?: DB, catalog?: CatalogDetails, priceGroups?: Record<string, CatalogPriceGroup | undefined>, isDataTable: boolean = false) {
  const value: any = article[attribute.SystemName]
  if (!isDefined(value)) { return '' }

  // TODO: Check for hidden
  // if(attribute._hidden) {
  //   return 'Invalid Property'
  // }
  if (attribute.SystemName === 'Status') {
    let statusString: string = ''
    switch (value) {
      case 1:
        statusString = !isDataTable ? 'Active' : 'True'
        break
      case 2:
        statusString = 'Not Assorted'
        break
      case 3:
        statusString = 'Globally Dropped'
        break
      default:
        statusString = !isDataTable ? 'Inactive' : 'False'
    }
    return statusString
  }
  else if (attribute.SystemName === '_RequestState') {
    let requestStatus = ''
    switch (value) {
      case requestConstants.requestStates.new:
        requestStatus = 'New'
        break
      case requestConstants.requestStates.approve:
        requestStatus = 'Approve'
        break
      case requestConstants.requestStates.reject:
        requestStatus = 'Reject'
        break
      case requestConstants.requestStates.draft:
        requestStatus = 'Draft'
        break
      case requestConstants.requestStates.confirm:
        requestStatus = 'Confirm'
        break
    }
    return requestStatus
  }
  else if (attribute.SystemName === '_RequestSource') {
    let requestSource = ''
    switch (value) {
      case requestConstants.requestSources.new:
        requestSource = 'New'
        break
      case requestConstants.requestSources.similar:
        requestSource = 'Similar'
        break
      case requestConstants.requestSources.carryover:
        requestSource = 'Carryover'
        break
      case requestConstants.requestSources.similarStyle:
        requestSource = 'Similar Style'
        break
    }
    return requestSource
  }
  else if (priceGroups && attribute.SystemName === '_WholesalePrice') {
    return formatPrice(priceGroups.wholesale, value, catalog?.Config.ShowPriceThousandsSeparated)
  }
  else if (priceGroups && attribute.SystemName === '_RetailPrice') {
    return formatPrice(priceGroups.retail, value, catalog?.Config.ShowPriceThousandsSeparated)
  }
  else if (priceGroups && attribute.SystemName === '_OutletPrice') {
    return formatPrice(priceGroups.outlet, value, catalog?.Config.ShowPriceThousandsSeparated)
  }
  else if (attribute.AttributeType === AttributeType.Date || attribute.AttributeType === AttributeType.DateOption) {
    return formatDate(value)
  }
  else if (attribute.AttributeType === AttributeType.DateTime) {
    return formatDateTime(value)
  }
  else if (attribute.AttributeType === AttributeType.Bool) {
    return value ? 'Yes' : 'No'
  }
  else if (Array.isArray(value) && attribute.AttributeType === AttributeType.MultiValue) {
    if (attribute.SystemName === '_FavoriteTags') {
      return value.map(x => x.Tag).join('|')
    }
    return value.join('|')
  }
  else if (attribute.AttributeType === AttributeType.LinkedCatalogArticleNumber && isValidStringValue(value)) {
    const splits = value.split('//')
    return (splits.length > 2) ? `${splits[2]}` : ''
  }
  else if (attribute.AttributeType === AttributeType.BoolInt || attribute.AttributeType === AttributeType.IntBool) {
    return value ? 'True' : 'False'
  }
  else if (isDefined(db) && isDefined(catalog) && isNumber(value) && attribute.AttributeType === AttributeType.ColorPalette) {
    const colorId = Number(value)
    let colorName: string | undefined = ''
    const colorPallets = await db.colorPallets.where('ColorIds').equals(colorId).toArray()
    if (colorPallets.length) {
      const catalogColorPallet = colorPallets.find(c => c.CatalogCode === catalog.CatalogCode)
      const color = (catalogColorPallet || colorPallets[0]).Colors.find(c => c.ColorId === colorId)
      colorName = color?.ColorName
      if (!isDefined(colorName) && isDefined(article.ColorName) && attribute.SystemName === 'ColorId') {
        colorName = article.ColorName
      }
      return `${colorName}${(catalogColorPallet ? '' : ' (Not In Pallet)')}`
    }
    else {
      if (isDefined(article.ColorName) && attribute.SystemName === 'ColorId') {
        colorName = article.ColorName
      }
      return `${colorName} (Not In Pallet)`
    }
  }
  return value
}

function getArticleAttributeValue(attribute: IMyAttribute, article: MyArticle | Article) {
  let value = article[attribute.SystemName]
  if (isDefined(value)) {
    if (attribute.AttributeType === AttributeType.Bool) {
      value = formatBoolean(value)
    }
    else if (Array.isArray(value) && attribute.AttributeType === AttributeType.MultiValue) {
      value = attribute.VettingList && attribute.VettingList.length > 0 ? value : value.join('\n')
    }
  }
  return value
}

function getArticleAttributeDefaultValue(attribute: IMyAttribute) {
  return attribute.AttributeType === AttributeType.Bool
    ? false
    : attribute.AttributeType === AttributeType.Int || attribute.AttributeType === AttributeType.Decimal
      ? 0
      : attribute.AttributeType === AttributeType.MultiValue && attribute.VettingList && attribute.VettingList.length > 0
        ? [] as string[]
        : ''
}

/**
 * @description this method converts number to string formatted with thousands separator
 * @param {number} value
 * @returns formatted string
 */
function thousandsSeparator(value: number | string) {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

function arrayToStringDictionary<T>(arr: T[], prop: string): Record<string, T> {
  return arr.reduce((acc, cur) => {
    acc[(cur as any)[prop]] = cur
    return acc
  }, {} as Record<string, T>)
}

function arrayToNumberDictionary<T>(arr: T[], prop: string): Record<number, T> {
  return arr.reduce((acc, cur) => {
    acc[(cur as any)[prop]] = cur
    return acc
  }, {} as Record<number, T>)
}

function formatBytes(bytes: number, decimals = 2) {
  if (bytes === 0) { return '0 Bytes' }

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${Number.parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`
}

function formatBoolean(val: any) {
  if (!isDefined(val)) { return false }

  switch (val.toString().toLowerCase().trim()) {
    case 'true':
    case 'yes':
    case '1':
      return true

    case 'false':
    case 'no':
    case '0':
    case null:
      return false

    default:
      return Boolean(val)
  }
}

/**
 * Compares 2 strings with possibility to ignore case
 *
 * @param {string} string1 The first string
 * @param {string} string2 The second string
 * @param {boolean} [ignoreCase] If true, will do a case-insensitive comparison
 * @param {boolean} [useLocale] If true and ignoreCase=true, then will do a case-insensitive comparison while maintaining locale
 * @returns {boolean} True if match, False otherwise
 */
function compareStrings(string1: string, string2: string, ignoreCase: boolean = false, useLocale: boolean = false) {
  let s1 = string1 || ''
  let s2 = string2 || ''

  if (ignoreCase) {
    if (useLocale) {
      s1 = s1.toLocaleLowerCase()
      s2 = s2.toLocaleLowerCase()
    }
    else {
      s1 = s1.toLowerCase()
      s2 = s2.toLowerCase()
    }
  }
  return s1 === s2
}

function hashCode(input: string) {
  let hash = 0
  const strlen = input.length
  let i, c

  if (strlen === 0) {
    return hash
  }

  for (i = 0; i < strlen; i++) {
    c = input.charCodeAt(i)
    hash = ((hash << 5) - hash) + c
    hash = hash & hash // Convert to 32bit integer
  }
  return hash
}

function getDelta<T>(oldArr: T[], newArr: T[]) {
  const oldSet = new Set(oldArr)
  const newSet = new Set(newArr)
  const res = { added: [] as T[], removed: [] as T[] }

  res.added = newArr.filter(itm => !oldSet.has(itm))
  res.removed = oldArr.filter(itm => !newSet.has(itm))

  return res
}

async function tryAsync<T>(fct: Promise<T>): Promise<{ success: true, result: T } | { success: false, error: any }> {
  try {
    const res = await fct
    return { success: true, result: res }
  }
  catch (error) {
    return { success: false, error }
  }
}

function tryParse(text: string) {
  try {
    return JSON.parse(text)
  }
  catch (error) {
    return null
  }
}

export class CancelToken {
  public requestCancelation: boolean = false
  public canceled: boolean = false
  public cancelPromise?: Promise<boolean>

  private cancelPromiseResolve: ((value: boolean | PromiseLike<boolean>) => void) | undefined

  cancel() {
    this.cancelPromise = new Promise<boolean>((resolve) => {
      this.cancelPromiseResolve = resolve
      this.requestCancelation = true
    })
    return this.cancelPromise
  }

  cancelComplete() {
    this.canceled = true
    if (this.cancelPromiseResolve) {
      this.cancelPromiseResolve(true)
    }
    return this
  }
}

export class EventBus {
  private subscriptions: { [event: string]: { [id: number]: (arg: any) => void } }
  private lastId = 0

  constructor() {
    this.subscriptions = {}
  }

  subscribe(eventType, callback): IEventBusSubscription {
    this.lastId++
    const id = this.lastId

    if (!this.subscriptions[eventType]) { this.subscriptions[eventType] = {} }

    this.subscriptions[eventType][id] = callback

    return {
      unsubscribe: () => {
        delete this.subscriptions[eventType][id]
        if (Object.keys(this.subscriptions[eventType]).length === 0) { delete this.subscriptions[eventType] }
      },
    }
  }

  publish(eventType: string, arg: any) {
    if (!this.subscriptions[eventType]) { return 0 }

    Object.keys(this.subscriptions[eventType]).forEach(key => this.subscriptions[eventType][key](arg))
    return Object.keys(this.subscriptions[eventType]).length
  }
}

export const sameWidthModifier: Modifier<string, Record<string, unknown>> = {
  name: 'sameWidthModifier',
  enabled: true,
  phase: 'beforeWrite' as ModifierPhases,
  requires: ['computeStyles'],
  fn({ state }) {
    state.styles.popper.minWidth = `${state.rects.reference.width}px`
  },
  effect({ state }) {
    state.elements.popper.style.minWidth = `${(state.elements.reference as any).offsetWidth}px`
  },
}

function randomString(length) {
  let result = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random()
    * charactersLength))
  }
  return result
}
function randomId() {
  return Math.floor(Date.now() * Math.random()).toString(36) + Math.floor(Date.now() * Math.random()).toString(36)
}

function setTitle(location: string, title: string = '') {
  const ttl = ['T1S', location]

  if (title !== '') { ttl.push(title) }
  document.title = truncateString(ttl.join(' | '), 60)
}

function getInitials(fullName) {
  const allNames = fullName.trim().split(' ')
  const initials = allNames.reduce((acc, curr, index) => {
    if (index === 0 || index === allNames.length - 1) {
      acc = `${acc}${curr.charAt(0).toUpperCase()}`
    }
    return acc
  }, '')
  return initials
}

function* filter(array: any[], condition: Function, limit: number = 0) {
  if (limit <= 0 || limit > array.length) {
    limit = array.length
  }
  let count = 0
  let i = 0
  while (count < limit && i < array.length) {
    if (condition(array[i])) {
      yield array[i]
      count++
    }
    i++
  }
}

function truncateString(str, num) {
  if (str.length > num) {
    return `${str.slice(0, num)}...`
  }
  else {
    return str
  }
}

function toRecord<
  T extends { [K in keyof T]: string | number | symbol },
  K extends keyof T,
>(array: T[], selector: K): Record<T[K], T> {
  return array.reduce((acc, item) => ({ ...acc, [item[selector]]: item }), {} as Record<T[K], T>)
}

function toRecordOneValue<
  T extends { [K in keyof T]: any },
  K extends keyof T,
>(array: T[], selector: K, key: K): Record<T[K], T> {
  return array.reduce((acc, item) => ({ ...acc, [item[selector]]: item[key] }), {} as Record<T[K], T>)
}

function isNumber(value) {
  return isDefined(value) && value.toString().trim().length && !Number.isNaN(Number(value))
}

function isDate(value: any) {
  return !Number.isNaN(value) && value instanceof Date
}

function isValidISODate(value) {
  // test if it matches the ISO string eg. 2023-08-21T07:54:39.350Z
  if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(value)) {
    return false
  }
  const dateObject = new Date(value)
  return dateObject instanceof Date && !Number.isNaN(dateObject.getTime()) && dateObject.toISOString() === value
}

function isNumberOrNumberString(value) {
  return isDefined(value) && value.toString().trim().length && !Number.isNaN(Number(value))
}

function isArrayOfNumbers(arr: any): arr is Array<number> {
  return Array.isArray(arr) && arr.length > 0 && typeof arr[0] === 'number'
}

function isErrorWithMessage(error: unknown): error is ErrorWithMessage {
  return (
    typeof error === 'object'
    && error !== null
    && 'message' in error
    && typeof (error as Record<string, unknown>).message === 'string'
  )
}

function toErrorWithMessage(maybeError: unknown): ErrorWithMessage {
  if (isErrorWithMessage(maybeError)) { return maybeError }

  try {
    return new Error(JSON.stringify(maybeError))
  }
  catch {
    // fallback in case there's an error stringifying the maybeError
    // like with circular references for example.
    return new Error(String(maybeError))
  }
}

function getErrorMessage(error: any) {
  return isDefined(error.response) && isDefined(error.response.data)
    ? error.response.data.toString()
    : toErrorWithMessage(error).message
}

async function delay(ms: number) {
  return new Promise(res => setTimeout(res, ms))
}

async function retryAsync<T>(fct: () => Promise<T>, retries: number = 3, wait: number = 2000) {
  let res: { success: true, result: T } | { success: false, error: any } = { success: false, error: undefined }
  for (let index = 0; index < retries; index++) {
    res = await tryAsync<T>(fct())
    if (res.success) {
      return res.result
    }
    await delay(wait)
  }
  throw res.error
}

/**
 * Inserts an array element at it's correctly sorted position
 *
 * @param {any} element The element to insert
 * @param {Array} array The array to insert into
 * @param {any} [comparer] Optional comparer
 * @returns {Array} The new sorted array
 */
function insertSorted(element: any, array: any[], comparer?: any) {
  let c = comparer
  if (!c) {
    c = function (a, b) {
      if (a < b) {
        return -1
      }
      else if (a > b) {
        return 1
      }
      else {
        return 0
      }
    }
  }
  array.splice(locationOf(element, array, c) + 1, 0, element)
  return array
}

function locationOf(element: any, array: any[], comparer?: any, start?: number, end?: number) {
  if (array.length === 0) {
    return -1
  }

  start = start || 0
  end = end || array.length
  const pivot = (start + end) >> 1 // should be faster than dividing by 2

  const c = comparer(element, array[pivot])
  if (end - start <= 1) { return c === -1 ? pivot - 1 : pivot }

  if (c < 0) {
    return locationOf(element, array, comparer, start, pivot)
  }
  else if (c > 0) {
    return locationOf(element, array, comparer, pivot, end)
  }
  else {
    return pivot
  }
}

/**
 * @description a method to sort an array in ascending order based on multiple properties
 * @param {Array} array list to be sort
 * @param {string[] | string} sortProperties list of properties based on which array needs to be sort
 * @param {boolean} isAscending an optional param defining sort direction
 */
function sort<T>(array: T[], sortProperties: string[] | string, isAscending = true): T[] {
  if (isDefined(sortProperties) && !Array.isArray(sortProperties)) {
    sortProperties = [sortProperties]
  }
  return array.sort((a, b) => comparer(a, b, sortProperties, undefined, isAscending))
}

function comparer(a: any, b: any, sortProperties: string[] | string, currentIndex = 0, isAscending = true) {
  const sortDirectionMultiple = isAscending === true ? 1 : -1
  if (typeof a[sortProperties[currentIndex]] === 'number' && typeof b[sortProperties[currentIndex]] === 'number') {
    if (a[sortProperties[currentIndex]] > b[sortProperties[currentIndex]]) {
      return 1 * sortDirectionMultiple
    }
    else if (a[sortProperties[currentIndex]] < b[sortProperties[currentIndex]]) {
      return -1 * sortDirectionMultiple
    }
    else {
      if (currentIndex < sortProperties.length - 1) {
        currentIndex += 1
        return comparer(a, b, sortProperties, currentIndex, isAscending)
      }
      else {
        return 0
      }
    }
  }
  const A_LOWER_CASE_VALUE = isDefined(a[sortProperties[currentIndex]])
    ? a[sortProperties[currentIndex]].toString().toLowerCase()
    : a[sortProperties[currentIndex]]
  const B_LOWER_CASE_VALUE = isDefined(b[sortProperties[currentIndex]])
    ? b[sortProperties[currentIndex]].toString().toLowerCase()
    : b[sortProperties[currentIndex]]
  if (A_LOWER_CASE_VALUE > B_LOWER_CASE_VALUE) {
    return 1 * sortDirectionMultiple
  }
  else if (A_LOWER_CASE_VALUE < B_LOWER_CASE_VALUE) {
    return -1 * sortDirectionMultiple
  }
  else {
    if (currentIndex < sortProperties.length - 1) {
      currentIndex += 1
      return comparer(a, b, sortProperties, currentIndex, isAscending)
    }
    else {
      return 0
    }
  }
}

/**
 * @description check if first 2 parameters have equal string value, null and undefined which denotes no value considered as equal
 * eg. following will return true
 * 1 & '1', null & undefined, true & 'True' (when caseInsensitive)
 * @param {any} valueA first value to be compared
 * @param {any} valueB second value to be compared
 * @param {boolean} caseInsensitive a boolean indicating case sensitivity for comparison
 * @returns {boolean} true/false
 */
function haveEqualStringValue(valueA, valueB, caseInsensitive = true) {
  if (caseInsensitive) {
    return ((!isDefined(valueA) && !isDefined(valueB)) || (isDefined(valueA) && isDefined(valueB) && valueA.toString().trim().toLowerCase() === valueB.toString().trim().toLowerCase()))
  }
  else {
    return ((!isDefined(valueA) && !isDefined(valueB)) || (isDefined(valueA) && isDefined(valueB) && valueA.toString().trim() === valueB.toString().trim()))
  }
}

function capitalizeFirstLetter(str: string) {
  return isValidStringValue(str) ? str.charAt(0).toUpperCase() + str.slice(1) : ''
}

function resetReactiveObject(reactiveObject: object) {
  for (const key in reactiveObject) {
    delete reactiveObject[key]
  }
}

/**
 * Uses canvas.measureText to compute and return the width of the given text of given font in pixels.
 *
 * @param {string} text The text to be rendered.
 * @param {string} font The css font descriptor that text is to be rendered with (e.g. "bold 10px arial").
 *
 * @see http://stackoverflow.com/questions/118241/calculate-text-width-with-javascript/21015393#21015393
 */
function getTextWidth(text: string, font: string) {
  const canvas = document.createElement('canvas')
  const context = canvas.getContext('2d')
  if (context) {
    context.font = font
    const metrics = context.measureText(`${text} `)
    return metrics.width
  }
  else {
    return 0
  }
}
function getTextHeight(text, fontSize, font, fabric) {
  const iTextObject = new fabric.IText(text, { fontSize, fontFamily: font })
  return iTextObject.height
}
/**
 * Uses fabric Textbox to compute and return the height of the given text of given font , font size and width.
 *
 * @param {string} text The text to be rendered.
 * @param {number} fontSize The css font size that text is to be rendered with
 * @param {string} font The css font descriptor that text is to be rendered with
 * @param {number} width width for the text
 * @param {*} fabric The fabric
 *
 */
function getTextBoxHeight(text, fontSize, font, width, fabric, isMerchFolderAvailable) {
  const textBoxObject = new fabric.Textbox(text, { width, top: 0, left: 0, textAlign: 'center', fontSize, fontFamily: font, splitByGrapheme: false })
  // If any word width is greater than the required width of textbox. Then width of textbox also increases.
  // So need to calculate the height with using new width, only for merch not for export
  if (width < textBoxObject.width && isMerchFolderAvailable) {
    const textBoxObjectIfWidthIncreased = new fabric.Textbox(text, { width: textBoxObject.width, top: 0, left: 0, textAlign: 'center', fontSize, fontFamily: font, flipX: false })
    return textBoxObjectIfWidthIncreased.height
  }
  else {
    return textBoxObject.height
  }
}
function addOrRemoveChildToFrames(frames: WbFrame[], wbObject: IWbObject) {
  if (wbObject && wbObject.id) {
    if (frames.length > 0) {
      for (let i = 0; i < frames.length; i++) {
        const frame = frames[i]
        if (wbObject.isContainedWithinObject(frame)) {
          frame.addChild(wbObject.id)
        }
        else if (frame.isChild(wbObject.id)) {
          frame.removeChild(wbObject.id)
        }
      }
    }
  }
}

function getStateProperties(obj: IWbObject | IMbObject) {
  // eslint-disable-next-line ts/ban-ts-comment
  // @ts-expect-error
  const stateProperties = obj._stateProperties
  return stateProperties
}

function getArticleAttributeTypeSpecificValue(attribute: IMyAttribute, value: any) {
  if (attribute.AttributeType === AttributeType.Bool) {
    return Boolean(value).toString()
  }
  else if (attribute.AttributeType === AttributeType.Int || attribute.AttributeType === AttributeType.Decimal) {
    return isValidStringValue(value) ? value : null
  }
  else if (attribute.AttributeType === AttributeType.MultiValue) {
    if (isDefined(value) && !Array.isArray(value)) {
      return value.split(/\r?\n/).reduce((acu, cur) => {
        if (isValidStringValue(cur)) {
          acu.push(cur)
        }
        return acu
      }, [])
    }
  }
  else if (attribute.SystemName === 'ColorId' && attribute.AttributeType === AttributeType.ColorPalette) {
    if (isDefined(value)) {
      const parsedJson = tryParse(value)
      return parsedJson ? Object.keys(parsedJson)[0] : value
    }
  }
  return value
}
// we can only have attributes in silhouette image criteria
function getPlaceHolderImageKey(SilhouetteImagesCriteriaConfiguration: Record<string, any>, article: Article, availableIndexedSilhouette: Record<string, DuneAsset>) {
  let silhouetteImageName = ''
  if (article && SilhouetteImagesCriteriaConfiguration && !isEmpty(SilhouetteImagesCriteriaConfiguration)) {
    for (const silhouetteName in SilhouetteImagesCriteriaConfiguration) {
      const silhouetteNameLower = silhouetteName.toString().trim().toLowerCase()
      if (availableIndexedSilhouette[silhouetteNameLower]) {
        let isSilhouetteNameValid = true
        for (const attributeSystemName in SilhouetteImagesCriteriaConfiguration[silhouetteName]) {
          const silhouetteImagesCriteriaConfigAttributeValue = SilhouetteImagesCriteriaConfiguration[silhouetteName][attributeSystemName] ? SilhouetteImagesCriteriaConfiguration[silhouetteName][attributeSystemName].toString().trim().toLowerCase() : ''
          const articleValue = article[attributeSystemName]
          if (articleValue == null || articleValue.toString().trim().toLowerCase() !== silhouetteImagesCriteriaConfigAttributeValue) {
            isSilhouetteNameValid = false
            break
          }
          if (isSilhouetteNameValid) {
            silhouetteImageName = silhouetteNameLower
            return silhouetteImageName
          }
        }
      }
    }
    return silhouetteImageName
  }
  return silhouetteImageName
}

function arrayBufferToBase64(arrayBuffer: ArrayBuffer): string {
  let binary = ''
  const bytes = new Uint8Array(arrayBuffer)
  const len = bytes.byteLength
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i])
  }
  return btoa(binary)
}

function validateExpression(currentValue: any, sourceFieldValue: any, validationExpressionItem: IValidationExpressionItem) {
  let isValid = false
  if (isValidStringValue(sourceFieldValue)) {
    isValid = validateCondition(currentValue, sourceFieldValue, validationExpressionItem.Expression)
  }
  else if (!validationExpressionItem.SourceValueRequired) {
    isValid = true
  }
  return isValid
}

function validateCondition(a: any, b: any, condition: string) {
  switch (condition) {
    case '<':
      return a < b
    case '>':
      return a > b
    case '<=':
      return a <= b
    case '>=':
      return a >= b
    case '=':
    case '==':
    case '===':
      return a === b
    case '!=':
    case '!==':
      return a !== b
  }
  return false
}
/**
 * @description Takes a positive integer and returns the corresponding column name.
 * @param {number} number  The positive integer to convert to a column name.
 * @return {string}  The column name.
 */
function numberToExcelColumnName(number: number) {
  const alpha = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
  if (number < 26) {
    return alpha[number - 1]
  }
  else {
    const q = Math.floor(number / 26)
    const r = number % 26
    if (r === 0) {
      if (q === 1) {
        return alpha[(26 + r - 1)]
      }
      else {
        return numberToExcelColumnName(q - 1) + alpha[(26 + r - 1)]
      }
    }
    else {
      return numberToExcelColumnName(q) + alpha[r - 1]
    }
  }
}

async function getRequestArticle(catalog: CatalogDetails, request: RequestModel, db?: DB) {
  const articleObj: any = clone(request.Content)
  articleObj.Id = request.Id
  articleObj.CatalogCode = request.CatalogCode
  articleObj.CatalogArticleId = request.ObjectId
  articleObj.Period = catalog._IndexedShipmentWindowRange[request.Content.ShipmentWindowRangeId] ? catalog._IndexedShipmentWindowRange[request.Content.ShipmentWindowRangeId].Period : request.Content.Period
  articleObj.Status = request.Status
  articleObj.Created_By_User = request.CreatedByUserName
  articleObj.Created_By = request.CreatedBy
  articleObj.Updated_By = request.UpdatedBy
  articleObj.CreatedDate = request.CreatedDate
  articleObj.UpdatedDate = request.UpdatedDate
  articleObj.CreatedByUserName = request.CreatedByUserName
  articleObj.UpdatedByUserName = request.UpdatedByUserName
  const requestArticlePrices = request.Content.ArticlePrices
  if (isDefined(requestArticlePrices) && isDefined(requestArticlePrices.Prices) && requestArticlePrices.Prices.length) {
    articleObj._Prices = {} as Record<number, Price>
    requestArticlePrices.Prices.forEach((price) => {
      articleObj._Prices[price.PriceGroupId] = { Id: request.Id, PriceGroupId: price.PriceGroupId, Price: price.Price }
    })
  }

  const requestArticle = new Article(catalog.CatalogCode, catalog.AssignedCatalogAttributes, articleObj, catalog.DataSourceTypeId)
  requestArticle._IsRequestArticle = true
  requestArticle._RequestNumber = request.RequestNumber
  requestArticle._RequestSource = request.RequestSource
  requestArticle._RequestState = request.State
  requestArticle._SourceModelId = request.SourceModelId
  requestArticle._SourceModelNumber = request.SourceModelNumber
  requestArticle._SourceArticleId = request.Content.ArticleId || request.SourceArticleId
  requestArticle._SourceArticleNumber = request.Content.ArticleNumber || request.SourceArticleNumber
  requestArticle._ConfirmArticleNumber = request.ArticleNumber
  requestArticle._Comment = request.Comment
  requestArticle._Reason = request.Reason
  if (catalog.RequestAttributeList && catalog.RequestAttributeList.length && request.RequestAttributes) {
    requestArticle.RequestAttributes = {}
    catalog.RequestAttributeList.forEach((requestAttribute) => {
      if (requestAttribute.Status > 0 && request.RequestAttributes!.hasOwnProperty(requestAttribute.AttributeSystemName)) {
        const requestAttributeValue = request.RequestAttributes![requestAttribute.AttributeSystemName]
        // For now, setting the first value, later we need to handle
        // eslint-disable-next-line ts/ban-ts-comment
        // @ts-expect-error
        requestArticle.RequestAttributes[requestAttribute.AttributeSystemName] = isDefined(requestAttributeValue) && requestAttributeValue.length ? requestAttributeValue[0] : ''
      }
    })
  }
  if (db && catalog.AssignedCatalogAttributes && catalog.AssignedCatalogAttributes.length
    && (requestArticle._RequestSource !== requestConstants.requestSources.similarStyle)) {
    let isArticleSeasonless = false
    let whereClause: string | null = null
    let whereValue: number | string | null = null
    let existingArticle: Article | undefined
    let isSeasonal = false
    if (requestArticle._RequestSource === requestConstants.requestSources.carryover) {
      isArticleSeasonless = true
      whereClause = request.SourceArticleId ? 'Id' : 'ArticleNumber'
      whereValue = request.SourceArticleId ? request.SourceArticleId : request.SourceArticleNumber
      const isModelExists = await db.articles.where(`[CatalogCode+ModelId]`).equals([catalog.CatalogCode, request.SourceModelId!]).first()
      if (isModelExists) {
        isSeasonal = true
      }
      existingArticle = await db.articles.where(whereClause).equals(whereValue).first()
    }
    else {
      whereClause = request.SourceModelId ? 'ModelId' : 'ModelNumber'
      whereValue = request.SourceModelId ? request.SourceModelId : request.SourceModelNumber
      const isModelExists = await db.articles.where(`[CatalogCode+${whereClause}]`).equals([catalog.CatalogCode, whereValue]).first()
      if (isModelExists) {
        existingArticle = isModelExists
        isSeasonal = true
      }
      else {
        existingArticle = await db.articles.where(whereClause).equals(whereValue).first()
      }
    }

    if (isDefined(existingArticle)) {
      catalog.AssignedCatalogAttributes.forEach((attribute) => {
        if ((attribute.IsSeasonlessModelAttribute || (isSeasonal && attribute.IsModelLevel) || (isArticleSeasonless && attribute.IsSeasonless))
          && attribute.AttributeTypeId !== AttributeType.Calc && !isDefined(requestArticle[attribute.AttributeSystemName])
          && isDefined(existingArticle) && isDefined(existingArticle[attribute.AttributeSystemName])) {
          requestArticle[attribute.AttributeSystemName] = existingArticle[attribute.AttributeSystemName]
        }
      })
    }
  }
  return requestArticle
}

function getRequestWaterTextAndFontColor(article: MyArticle | Article) {
  let waterMarkText = ''
  let fontColor = '#0d5ebb6e'

  if (article._RequestState === requestConstants.requestStates.approve) {
    fontColor = '#46d147c7'
  }
  else if (article._RequestState === requestConstants.requestStates.reject) {
    fontColor = '#f45c5bad'
  }
  else if (article._RequestState === requestConstants.requestStates.confirm) {
    fontColor = '#44eb7b'
  }
  if (article._IsRequestArticle) {
    const prefix = article._RequestState === requestConstants.requestStates.approve
      ? i18n.global.t('requests.approved')
      : article._RequestState === requestConstants.requestStates.reject
        ? i18n.global.t('requests.rejected')
        : article._RequestState === requestConstants.requestStates.confirm
          ? i18n.global.t('requests.confirmed')
          : ''
    waterMarkText = prefix && prefix.length ? `${prefix} ${i18n.global.t('general.request')}` : i18n.global.t('general.request')
    if (article._RequestSource === requestConstants.requestSources.new) {
      waterMarkText = article._RequestState === requestConstants.requestStates.approve
        ? i18n.global.t('requests.approveArticleRequest')
        : article._RequestState === requestConstants.requestStates.reject
          ? i18n.global.t('requests.rejectArticleRequest')
          : article._RequestState === requestConstants.requestStates.confirm
            ? i18n.global.t('requests.confirmArticleRequest')
            : i18n.global.t('requests.newArticleRequest')
    }
    else if (article._RequestSource === requestConstants.requestSources.carryover) {
      waterMarkText = prefix && prefix.length ? `${prefix} ${i18n.global.t('requests.carryoverArticleRequest')}` : i18n.global.t('requests.carryoverArticleRequest')
    }
    else if (article._RequestSource === requestConstants.requestSources.similarStyle) {
      waterMarkText = prefix && prefix.length ? `${prefix} ${i18n.global.t('requests.similarModelRequest')}` : i18n.global.t('requests.similarModelRequest')
    }
  }
  return { fontColor, waterMarkText }
}

/**
 * @description find and returns the maximum updated date from a list containing UpdatedDate property, null if array is empty
 * @param {Array} entityArray objects containing UpdatedDate property
 * @param {string} updateProperty update property
 * @param {string} nestedPropertyName nested property name
 * @returns {null | string} the maximum updated date from a list containing UpdatedDate property
 */
function getMaxUpdatedDate(entityArray, updateProperty = 'UpdatedDate', nestedPropertyName: string | null = null) {
  if (nestedPropertyName) {
    return entityArray.reduce((acu, cur) => {
      const nestedArray = cur[nestedPropertyName] || []
      nestedArray.forEach((nestedObject) => {
        // If the updateProperty is undefined set null
        // As discussed with @amardeep ignoring '0001-01-01T00:00:00.000Z' date which is set as the default vlaue from API side
        if (nestedObject[updateProperty] !== '0001-01-01T00:00:00.000Z') {
          const nestedDate = (nestedObject[updateProperty] && nestedObject[updateProperty] !== null) ? new Date(nestedObject[updateProperty]).toISOString() : null
          if (acu === null || (nestedDate !== null && nestedDate > acu)) {
            acu = nestedDate
          }
        }
      })
      return acu
    }, null)
  }
  else {
    return entityArray.reduce((acu, cur) => {
      // If the updateProperty is undefined set null
      // As discussed with @amardeep ignoring '0001-01-01T00:00:00.000Z' date which is set as the default vlaue from API side
      if (cur[updateProperty] !== '0001-01-01T00:00:00.000Z') {
        const currentDate = (cur[updateProperty] && cur[updateProperty] !== null) ? new Date(cur[updateProperty]).toISOString() : null
        if (acu === null || (currentDate !== null && currentDate > acu)) {
          acu = currentDate
        }
      }
      return acu
    }, null)
  }
}

function generateArticlesSortOrder(article: MyArticle, customSortOrder: { sortOrder: Record<string, number>, orderedAttributeSystemName: string[] } | undefined) {
  let articleSortOrder: string = ''
  if (isDefined(customSortOrder) && !isEmpty(customSortOrder) && customSortOrder.hasOwnProperty('orderedAttributeSystemName')
    && Array.isArray(customSortOrder.orderedAttributeSystemName) && customSortOrder.hasOwnProperty('sortOrder') && isObject(customSortOrder.sortOrder)) {
    const sortOrderLowerCase = {}
    for (const key in customSortOrder.sortOrder) {
      sortOrderLowerCase[key.toString().trim().toLowerCase()] = customSortOrder.sortOrder[key]
    }
    article.SortOrder = isDefined(article.SortOrder) ? article.SortOrder : 0
    let attributeConcatenatedValue = ''
    customSortOrder.orderedAttributeSystemName.forEach((attributeSystemName) => {
      attributeConcatenatedValue += isDefined(article[attributeSystemName]) ? article[attributeSystemName]!.toString().trim().toLowerCase() : article[attributeSystemName]
    })
    if (sortOrderLowerCase.hasOwnProperty(attributeConcatenatedValue)) {
      articleSortOrder = sortOrderLowerCase[attributeConcatenatedValue].toString().padStart(6, 0)
    }
    else {
      let defaultSortOrder = 'z'
      defaultSortOrder += article.SortOrder.toString().padStart(6, '0')
      defaultSortOrder += article.ArticleNumber
      articleSortOrder = defaultSortOrder
    }
  }
  else {
    article.SortOrder = isDefined(article.SortOrder) ? article.SortOrder : 0
    let sortOrder = ''
    sortOrder += article.SortOrder.toString().padStart(6, '0')
    sortOrder += article.ArticleNumber
    articleSortOrder = sortOrder
  }
  return articleSortOrder
}

function sortMyArticles(arts: MyArticle[]) {
  // Sort by _SortOrder, then SortOrder, then by ArticleNumber
  return arts.sort((a, b) => {
    if (a._SortOrder < b._SortOrder) {
      return -1
    }
    else if (a._SortOrder > b._SortOrder) {
      return 1
    }
    else {
      if ((!isNumber(a.SortOrder) || !isNumber(b.SortOrder) || a.SortOrder === b.SortOrder)) {
        const la = a.ArticleNumber ? a.ArticleNumber.toLowerCase() : ''
        const lb = b.ArticleNumber ? b.ArticleNumber.toLowerCase() : ''

        return la > lb ? 1 : la < lb ? -1 : 0
      }
      return a.SortOrder > b.SortOrder ? 1 : -1
    }
  })
}

export default {
  isDefined,
  ifUndefined,
  formatDate,
  formatDateTime,
  formatDateUTC,
  validateDate,
  formatPrice,
  isValidStringValue,
  getAttributeTypeSpecificValue,
  getAdminAttributeValue,
  getArticleAttributeValue,
  getArticleAttributeDefaultValue,
  thousandsSeparator,
  arrayToStringDictionary,
  arrayToNumberDictionary,
  formatBytes,
  formatBoolean,
  compareStrings,
  CancelToken,
  hashCode,
  getDelta,
  tryAsync,
  tryParse,
  sameWidthModifier,
  randomString,
  setTitle,
  getInitials,
  filter,
  truncateString,
  toRecord,
  toRecordOneValue,
  isNumber,
  isDate,
  isValidISODate,
  isNumberOrNumberString,
  isArrayOfNumbers,
  getErrorMessage,
  retryAsync,
  delay,
  insertSorted,
  sort,
  comparer,
  haveEqualStringValue,
  capitalizeFirstLetter,
  resetReactiveObject,
  getTextWidth,
  getTextHeight,
  getTextBoxHeight,
  addOrRemoveChildToFrames,
  getStateProperties,
  getArticleAttributeTypeSpecificValue,
  getPlaceHolderImageKey,
  arrayBufferToBase64,
  validateExpression,
  validateCondition,
  numberToExcelColumnName,
  randomId,
  getRequestArticle,
  getRequestWaterTextAndFontColor,
  getMaxUpdatedDate,
  generateArticlesSortOrder,
  sortMyArticles,
  isValidDateWithFormat,
}
